import {useMutation} from "@tanstack/react-query";
import {useAppwrite} from "../AppwriteContext";

const uploadForm = async (uploadFn, data) => {
    const response = await uploadFn.createExecution('67341f7f003738bddf29', JSON.stringify(
      data
    ))
      console.clear()
    console.log(`Response:`, response)
    return response
}

export const useUploadForm = () => {
    const {uploadFun} = useAppwrite()
    return useMutation({
        mutationFn: data => uploadForm(uploadFun, data),
        onError: error => console.log(error)
    })
}