import {createContext, useContext} from "react";
import  {Client,Functions} from  'appwrite'
const AppwriteContext = createContext({});

function AppwriteContextProvider({ children }) {

    const client =new Client()
        .setEndpoint('https://cloud.appwrite.io/v1')
        .setProject('6725cead00381d36af3f')


    const uploadFun =new Functions(client)

    const value ={
         uploadFun
    }
    return <AppwriteContext.Provider value={value}>{children}</AppwriteContext.Provider>;
}

export default AppwriteContextProvider;


export const useAppwrite = () => {
    return useContext(AppwriteContext);
}