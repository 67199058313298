import '../App.css'
import {useNavigate} from "react-router-dom";
import ReactGa4 from 'react-ga4'

function BlogScreen() {
    ReactGa4.send({
        hitType:"pageview",
        page:"/blog",
        title:"blog"
    })
    return (
        <div >
            <NavBar/>
            <Blog/>
        </div>
    )
}

function NavBar(){
    const navigate =useNavigate()
      var nav = <>
        <nav class="navbar navbar-expand-lg  ">
            <div class="container-fluid">
                <a class="navbar-brand" href="https://smmnw.com">Sai Mg Mg Nyan Win</a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav mx-auto">
                        <li className="nav-item" onClick={()=>{
                            navigate('/')
                        }}>
                            <a className="nav-link " aria-current="page">
                                Home
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" aria-current="page" >Blog</a>
                        </li>

                    </ul>
                </div>
            </div>
        </nav>
    </>;
    return nav
}

function Blog(){
    return (
        <section className="blog">
            <div className="container text-center p-3 display-5">
                <p>Launching Soon!!</p>
            </div>

        </section>
    )
}

export default BlogScreen