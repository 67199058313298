import '../App.css';
import dev from '../dev.svg'
import React, {useRef, useState} from "react";
import {useUploadForm} from "../store/form/useUploadForm";
import {NavLink} from "react-router-dom";
import ReactGA4 from 'react-ga4'

function PortfolioScreen() {
    const heroRef = useRef(null);
    const servicesRef = useRef(null);
    const aboutRef = useRef(null);
    const projectsRef = useRef(null);
    const contactRef = useRef(null);
      ReactGA4.send({
          hitType:"pageview",
          page:"/",
          title:"Home"
      })
    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({behavior: 'smooth'})
    }

    return (
        <div>
            <NavBar
                scrollToSection={scrollToSection}
                heroRef={heroRef}
                servicesRef={servicesRef}
                aboutRef={aboutRef}
                projectsRef={projectsRef}
                contactRef={contactRef}
            />
            <HeroSection ref={heroRef} contactRef={contactRef} scrollToSection={scrollToSection}/>
            <ServicesSection ref={servicesRef}/>
            <AboutSection ref={aboutRef}/>
            <ProjectsSection ref={projectsRef}/>
            <ContactSection ref={contactRef}/>
            <FooterSection/>
        </div>);
}


function NavBar({scrollToSection, heroRef, servicesRef, aboutRef, projectsRef, contactRef}) {
    return (<nav className="navbar   navbar-expand-lg  fixed-top ">
        <div className="container-fluid">
            <a className="navbar-brand" href="/">Sai Mg Mg Nyan Win</a>
            <button className="navbar-toggler " type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon "></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav mx-auto">

                    <li className="nav-item">
                        <a className="nav-link "
                           onClick={() => scrollToSection(heroRef)}>Home</a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" onClick={() => scrollToSection(servicesRef)}>Services</a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link"  onClick={() => scrollToSection(aboutRef)}>About</a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" onClick={() => {
                            scrollToSection(projectsRef)
                        }}>Projects</a>
                    </li>

                    <li className="nav-item">
                        <NavLink to='/blog' className="nav-link">Blog</NavLink>
                    </li>

                    <li className="nav-item d-lg-none">
                        <a className="nav-link" onClick={() => {
                            scrollToSection(contactRef)
                        }}>Contact</a>
                    </li>


                    {/*<li className="nav-item ">*/}
                    {/*    <a className="nav-link" href="#">Testimonials</a>*/}
                    {/*</li>*/}

                </ul>
                <a className='btn btn-outline-dark d-none d-lg-block ' onClick={() => scrollToSection(contactRef)}>Let's
                    Talk</a>
            </div>
        </div>
    </nav>)
}


const HeroSection = React.forwardRef((props, ref) => {
    return (
        <section className='hero' ref={ref}>
        <div className='container'>
            <div className='row align-items-center'>
                <div className='col-sm-6 '>
                    <h1 className='display-4 fw-bold main-header'>Bringing Your Ideas to Life</h1>
                    <p className='display-6'>Freelance developer creating high-performance mobile apps with React Native and dynamic
                        websites with React.js. Let’s bring your ideas to life with fast, responsive digital
                        solutions</p>
                    <a className='btn btn-outline-dark' onClick={()=>{
                        props.scrollToSection(props.contactRef)
                    }}>Contact Me Today</a>
                </div>
                <div className='col-sm-6 text-center'>
                    <img src={dev} alt='Developer Logo' className='img-fluid'/>
                </div>
            </div>
        </div>
    </section>)
})

const ServicesSection = React.forwardRef((props, ref) => {
    return (<section className='services' ref={ref}>
        <div className='container'>
            <h2 className='display-5 fw-bold mb-4'>Services</h2>
            <div className='row'>
                <div className='col-lg col-sm-6 mt-4'>
                    <div className="card">
                        <i className="bi bi-phone"></i>
                        <div className="card-body">
                            <h5 className="card-title">Moblie App Development</h5>
                            <p className="card-text">I develop Android and iOS apps using React Native and JavaScript,
                                with or without Expo. Let’s create efficient, cross-platform mobile solutions customized
                                for your needs.</p>

                        </div>
                    </div>
                </div>
                <div className='col-lg col-sm-6 mt-4'>
                    <div className="card">
                        <i className="bi bi-phone"></i>
                        <div className="card-body">
                            <h5 className="card-title">Website Development</h5>
                            <p className="card-text">I create websites using React.js, JavaScript, and Bootstrap,
                                delivering responsive and dynamic web experiences. Let’s build a visually stunning,
                                high-performance site that achieves your business objectives.</p>

                        </div>
                    </div>
                </div>
                <div className='col-lg col-sm-6 mt-4'>
                    <div className="card">
                        <i className="bi bi-phone"></i>
                        <div className="card-body">

                            <h5 className="card-title">Cross(Desktop) Platflom Development</h5>
                            <p className="card-text">I develop cross-platform software for Mac, Linux, and Windows using
                                React.js, JavaScript, and Electron.js. Let’s build applications with a seamless user
                                experience and robust functionality.</p>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>)
})

const AboutSection = React.forwardRef((props, ref) => {

    const [isIframeLoaded, setIframeLoaded] = useState(false);
    return (<section className='about' ref={ref}>
            <div className='container'>
            <h1>About Me</h1>
            <p>
                I’m a freelance developer skilled in <strong>React.js</strong>, <strong>React Native</strong>,
                and <strong>Electron.js</strong>, creating responsive
                web, mobile, and desktop applications using <strong>JavaScript</strong>.
            </p>
            <hr/>
            <h2>Certificates</h2>
            <div className="row">
                {/* Meta Front-End Developer Certificate */}
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Meta Front-End Developer Certificate</h5>
                            {isIframeLoaded ? (
                                <iframe
                                    src="https://www.credly.com/badges/0eb3f3fd-4f48-4bed-908b-1799394ef327/public_url"
                                    width="150"
                                    height="270"
                                    onLoad={() => setIframeLoaded(true)}
                                    onError={() => setIframeLoaded(false)}
                                    style={{border: 'none'}}
                                    title="Meta Front-End Developer Certificate"
                                ></iframe>
                            ) : (
                                <a
                                    href="https://www.credly.com/badges/0eb3f3fd-4f48-4bed-908b-1799394ef327/public_url"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn btn-primary"
                                >
                                    View Credential on Credly
                                </a>
                            )}
                        </div>
                    </div>
                </div>

                {/* Meta React Native Certificate */}
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Meta React Native Certificate</h5>
                            <a
                                href="https://www.coursera.org/account/accomplishments/specialization/QRUR3SEPKVR5"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-primary"
                            >
                                View Credential on Coursera
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
    )
})

const ProjectsSection = React.forwardRef((props, ref) => {
    return (<section className='projects' ref={ref}>
        <div className='container'>
            <h2>Portfolio</h2>
            <div className='row'>
                <div className='col-lg-3 col-sm-6 mt-4'>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">ToDo App(mobile)</h5>
                            <p className="card-text">I developed a cross-platform mobile To-Do app using React Native,
                                integrated with Appwrite. It features CRUD functionality, showcasing my expertise in
                                building efficient, user-focused mobile solutions.</p>
                            <a href="https://github.com/smmnw/tasks" className="btn btn-outline-dark">Go Github</a>
                        </div>
                    </div>
                </div>
                <div className='col-lg-3 col-sm-6 mt-4'>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Chat App(Mobile)</h5>
                            <p className="card-text">I’m currently developing a cross-platform mobile chat app using
                                React Native, integrated with Appwrite. It features user-to-user chat and group chat,
                                showcasing my skills in building dynamic, user-focused communication solutions.</p>
                            <button href="#" className="btn btn-outline-dark" disabled>Launching Soon!</button>
                        </div>
                    </div>
                </div>
                <div className='col-lg-3 col-sm-6 mt-4'>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Blog App(moblie)</h5>
                            <p className="card-text"> I’m currently developing a cross-platform mobile blog app using
                                React Native, integrated with Appwrite. Users can create posts, and only authorized
                                users can read them, demonstrating my ability to build secure, user-focused mobile
                                applications.</p>
                            <button href="#" className="btn btn-outline-dark " disabled>Launching Soon!</button>
                        </div>
                    </div>
                </div>
                <div className='col-lg-3 col-sm-6 mt-4'>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Portfolio(Web)</h5>
                            <p className="card-text">I built a portfolio website with React, Bootstrap, and Appwrite,
                                featuring responsive design, dynamic interfaces, and secure backend integration to
                                showcase modern, user-focused web development skills.</p>
                            <a href="/" className="btn btn-outline-dark">This Site</a>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </section>)
})

const ContactSection = React.forwardRef((props, ref) => {
    const {mutate, error, isSuccess, isLoading} = useUploadForm()
    const [formData, setFormData] = useState({
        email: '', name: '', service: 'Mobile App Only', comment: ''
    })

    const handleChange = e => {
        const {name, value} = e.target
        setFormData(prev => ({...prev, [name]: value}))
    }

    const handleSubmit = e => {

        e.preventDefault()
        mutate(formData)
    }
    return (<section className='contact' ref={ref}>
        <div className='container'>
            <h2 className='display-5  main-header fw-bold mb-4 '>Contact Me</h2>

            <div className='row'>
                <div className='col-md-6 mt-4 text-start'>

                    <a href="mailto:saimgmg.nyanwin@smmnw.com">saimgmg.nyanwin@smmnw.com</a><br/>
                    <a href="https://smmnw.com">smmnw.com</a><br/>
                    <a href="https://wa.me/959400414212"> <i className="bi bi-whatsapp"> Chat On WhatsApp</i></a>
                </div>
                <div className='col-6 mt-4'>
                    <div className='social-media'>


                        <a href="https://www.upwork.com/freelancers/~01d05caed422799fd6" target="_blank"
                           rel="noopener noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 viewBox="0 0 641 512">
                                <path
                                    d="M494.7 295.6c-50.3 0-83.5-38.9-92.8-53.9c11.9-95.3 46.8-125.4 92.8-125.4c45.5 0 80.9 36.4 80.9 89.7s-35.4 89.7-80.9 89.7zm0-237.8c-81.9 0-127.8 53.4-141 108.4c-14.9-28-25.9-65.5-34.5-100.3H206v141c0 51.1-23.3 89-68.8 89s-71.6-37.8-71.6-89l.5-141H.8v141c0 41.1 13.3 78.4 37.6 105.1c25 27.5 59.2 41.8 98.8 41.8c78.8 0 133.8-60.4 133.8-146.9V112.1c8.2 31.2 27.8 91.1 65.3 143.6l-35 199.4h66.4l23.1-141.3c7.6 6.3 15.7 12 24.2 17c22.2 14 47.7 21.9 73.9 22.8c0 0 4 .2 6.1 .2c81.2 0 145.9-62.9 145.9-147.8s-64.8-148.1-146-148.1z"/>
                            </svg>

                        </a>
                        <a href="https://facebook.com/smmnw.dev" target="_blank" rel="noopener noreferrer"><i
                            className="bi bi-facebook"></i></a>

                        <a href="https://x.com/smmnw_dev" target="_blank" rel="noopener noreferrer"><i
                            className="bi bi-twitter-x"></i></a>
                        <a href="https://www.linkedin.com/in/smmnw" target="_blank"
                           rel="noopener noreferrer"><i className="bi bi-linkedin"></i></a>
                    </div>
                </div>

                <div className='col-md-12 mt-4'>
                    <form onSubmit={handleSubmit}>
                        {error && (<div className='alert alert-danger'>
                            {error}
                        </div>)}

                        {isSuccess && (<div className='alert alert-success'>
                            <h2 className='display-6'>Thanks for contact us!</h2>
                        </div>)}

                        {isLoading && (<div className="spinner-grow text-primary" role="status">

                        </div>)}
                        <div className='form-group'>
                            <label htmlFor='email'>Email</label>
                            <input className='form-control mt-2' placeholder="Enter email" name='email'
                                   value={formData.email} onChange={handleChange}/>
                        </div>
                        <div className='form-group'>
                            <label htmlFor='Name'>Name</label>
                            <input className='form-control mt-2' placeholder="Enter your name" name='name'
                                   value={formData.name} onChange={handleChange}/>
                        </div>
                        <div className='form-group'>
                            <label htmlFor='services'>Choose Service</label>
                            <select className='form-select form-control mt-2' value={formData.service} name='service'
                                    onChange={handleChange}>
                                <option>Mobile App Only</option>
                                <option>Mobile App With Backend</option>
                                <option>Website Only</option>
                                <option>Website with Backend</option>
                            </select>
                        </div>
                        <div className='form-group'>
                                <textarea className='form-control mt-2'
                                          row='10'
                                          onChange={handleChange}
                                          placeholder='More Information'
                                          name='comment'
                                          value={formData.comment}/>
                        </div>
                        <button type='submit' className='btn btn-outline-dark'>Submit!</button>
                    </form>


                </div>
            </div>
        </div>
    </section>)
})

function FooterSection() {
    return (<footer className='footer text-center p-3 bg-body-tertiary'>
        <div>© 2024. All Rights Reserved.</div>
    </footer>)
}


export default PortfolioScreen;
